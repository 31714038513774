<template>
  <b-container>
    <div class="f-radius b-white row pb-0">
      <b-container class="px-0 child-container">

        <b-row>
          <b-col class="d-flex">
            <div class="search-result-item row f-radius b-white cursor-pointer">
              <div class="thumbnail">
                <div class="img"
                     :style="imgStyle(getImage(detail.customer_history_info.photo_path))"
                     v-lazy:background-image="getImage(detail.customer_history_info.photo_path)"
                ></div>
              </div>
            </div>
            <span class="p__top--30 m__left--10 font-weight-bold">No.{{ this.customerNo }}</span>
          </b-col>
          <b-col class="p__top--30 font-weight-bold">
            <p class="m--0">{{ detail.customer_history_info.customer_name }}</p>
            <p class="m--0">{{ detail.customer_history_info.customer_namekana }}</p>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex p__right--9 p__top--10">
            <b-card class="card-timer w--100">
              <b-card-header>生年月日</b-card-header>
              <b-card-text class="card-timer-content font-weight-bold">
                {{ detail.customer_history_info.customer_birthday != null ? dateFormat(detail.customer_history_info.customer_birthday) : '----/--/--' }}
              </b-card-text>
            </b-card>
          </b-col>
          <b-col class="d-flex p__left--9 p__top--10">
            <b-card class="card-timer w--100">
              <b-card-header>年齢</b-card-header>
              <b-card-text class="card-timer-content font-weight-bold">{{ detail.customer_history_info.customer_age != null ? detail.customer_history_info.customer_age : '--' }}</b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex p__right--9 p__top--10">
            <b-card class="card-timer w--100">
              <b-card-header>性別</b-card-header>
              <b-card-text class="card-timer-content font-weight-bold">{{ gender }}</b-card-text>
            </b-card>
          </b-col>
          <b-col class="d-flex p__left--9 p__top--10">
            <b-card class="card-timer w--100">
              <b-card-header>来店回数</b-card-header>
              <b-card-text class="card-timer-content font-weight-bold">{{ detail.customer_history_info.times }}回</b-card-text>
            </b-card>
          </b-col>
        </b-row>

        <b-row>
          <b-col class="d-flex p__right--9 p__top--10">
            <b-card class="card-timer w--100">
              <b-card-header>ポイント数</b-card-header>
              <b-card-text class="card-timer-content font-weight-bold">{{ detail.total_point }}</b-card-text>
            </b-card>
          </b-col>
          <b-col class="d-flex p__left--9 p__top--10">
            <b-card header="ポイント有効期限" class="card-timer w--100" header-tag="header">
              <b-card-text class="card-timer-content font-weight-bold">{{ detail.total_limited_point }}</b-card-text>
            </b-card>
          </b-col>
        </b-row>

      </b-container>
    </div>

    <b-container class="px-0 m__top--32 card-container">
      <b-card header="会員メモ" header-tag="header">
        <b-card-text class="d-flex">
          <b-textarea class="m--10" rows="4" v-model="detail.customer_history_info.customer_memo"></b-textarea>
        </b-card-text>
        <b-card-footer>
          <div class="text-center">
            <img src="@/assets/images/icons/ic_save.svg" alt="" width="30" @click="changeCustomerMemo">
          </div>
        </b-card-footer>
      </b-card>
    </b-container>

    <b-container :class="['px-0', 'm__top--32', 'card-container', { 'is-pink': detail.customer_history_info.status !== '精算済' }]">
      <b-card>
        <b-card-header class="p__top--0 p__bottom--0">
          <b-row>
            <p class="font-size-1 font-weight-600 text-center m--0 p__top--6 p__bottom--6 w--100 border-bottom-light">
              {{ reservedFormat(detail.customer_history_info.reserved_at) }}〜
            </p>
          </b-row>
          <b-row>
            <p class="font-size-1 font-weight-600 text-center m--0 p__top--6 p__bottom--6 w--100">{{ detail.customer_history_info.status }}</p>
          </b-row>
        </b-card-header>
        <b-card-text class="text-center p--15">
          <b-button class="card-button" @click="customerReserved">来店履歴</b-button>
        </b-card-text>
      </b-card>
    </b-container>

    <b-container class="px-0 m__top--32 card-container is-group">

      <b-card header="施術メニュー" header-tag="header">
        <div class="p--15">
          <b-card-text v-for="(menu, menuKey) in detail.customer_history_info.menu_array" :key="menuKey">
            {{ menu }}
          </b-card-text>
        </div>
      </b-card>

      <b-card header="担当" header-tag="header">
        <b-card-text class="p--15">{{ detail.customer_history_info.operate_staff_name }}</b-card-text>
<!--        <b-card-footer class="text-center p&#45;&#45;15">-->
<!--          <b-button class="card-button">指名</b-button>-->
<!--        </b-card-footer>-->
      </b-card>

      <b-card header="予約メモ" header-tag="header">
        <b-card-text class="d-flex">
          <b-textarea class="m--10" rows="4" v-model="detail.customer_history_info.customer_history_memo"></b-textarea>
        </b-card-text>
        <b-card-footer>
          <div class="text-center">
            <img src="@/assets/images/icons/ic_save.svg" alt="" width="30" @click="changeCustomerHistoryMemo">
          </div>
        </b-card-footer>
      </b-card>

      <b-card header="イラスト" header-tag="header">
        <b-card-text
            v-for="(illust, illustKey) in detail.customer_history_illust" :key="illustKey"
            :class="['d-flex', {'border-bottom-grey': illustKey !== detail.customer_history_illust.length - 1}]"
        >
          <b-col class="d-flex">
            <div class="search-result-item row f-radius b-white cursor-pointer" @click="showIllust(illust)">
              <div class="thumbnail">
                <div class="img" :style="imgBase64Style(illust.img)"></div>
              </div>
            </div>
            <span class="p__top--20 m__left--10 font-size-12 cursor-pointer" @click="showIllust(illust)">{{ illust.comment }}</span>
          </b-col>
        </b-card-text>
        <b-card-footer>
          <div class="text-center">
            <img v-b-modal.illust-modal src="@/assets/images/icons/ic_plus.svg" alt="" width="30">
          </div>
        </b-card-footer>
      </b-card>

      <b-card header="写真" header-tag="header">
        <b-card-text
            v-for="(photo, photoKey) in detail.customer_history_photo" :key="photoKey"
            :class="['d-flex', {'border-bottom-grey': photoKey !== detail.customer_history_photo.length - 1}]"
        >
          <b-col class="d-flex">
            <div class="search-result-item row f-radius b-white cursor-pointer" @click="showPhoto($event, photo)">
              <div class="thumbnail">
                <div class="img"
                     :style="imgStyle(getImage(photo.img))"
                     v-lazy:background-image="getImage(photo.img)"
                ></div>
              </div>
            </div>
            <span class="p__top--20 m__left--10 font-size-12 cursor-pointer" @click="showPhoto($event, photo)">{{ photo.comment }}</span>
          </b-col>
        </b-card-text>
        <b-card-footer>
          <div class="text-center">
            <img v-b-modal.photo-modal src="@/assets/images/icons/ic_camera.svg" alt="" width="30">
          </div>
        </b-card-footer>
      </b-card>

      <b-card header="問診票写真" header-tag="header">
        <b-card-text
            v-for="(concent, concentKey) in detail.customer_history_concent" :key="concentKey"
            :class="['d-flex', {'border-bottom-grey': concentKey !== detail.customer_history_concent.length - 1}]"
        >
          <b-col class="d-flex">
            <div class="search-result-item row f-radius b-white cursor-pointer" @click="showConcent(concent)">
              <div class="thumbnail">
                <div class="img"
                     :style="imgStyle(getImage(concent.img))"
                     v-lazy:background-image="getImage(concent.img)"
                ></div>
              </div>
            </div>
          </b-col>
        </b-card-text>
        <b-card-footer>
          <div class="text-center">
            <img v-b-modal.concent-modal src="@/assets/images/icons/ic_camera.svg" alt="" width="30">
          </div>
        </b-card-footer>
      </b-card>

    </b-container>

    <b-modal id="illust-modal" centered hide-header hide-footer @shown="illustModalShown">
      <header class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="closeModal('illust-modal')">
          <img src="@/assets/images/icons/ic_exit.svg" alt="">
        </button>
      </header>

      <div class="tool-bar d-flex justify-content-center m__bottom--10">
        <div class="d-flex">
          <p class="mb-0">Color</p>
          <div :class="['choice', 'color-choice', { 'active': showColorPicker }]" :style="{ 'background-color': getColor }" @click="clickShowColorPicker" data-target="show-color-picker"></div>

          <p class="mb-0 ml-2">Line</p>
          <div :class="['choice', 'line-choice', { 'active': showLinePicker }]" @click="showLinePicker = !showLinePicker" data-target="show-line-picker">
            <div :style="{ 'width': line + 'px', 'height': line + 'px', 'background-color': getColor }" data-target="show-line-picker"></div>
          </div>

          <p class="mb-0 ml-2">Eraser</p>
          <div :class="['choice', 'eraser', { 'active': eraser }]" @click="eraser = !eraser"></div>
        </div>
      </div>
      <div class="position-relative">
        <div v-click-out="closeColorPicker" v-if="showColorPicker" class="f-color-picker">
          <sketch-picker v-model="color"></sketch-picker>
        </div>
        <div v-click-out="closeLinePicker" v-if="showLinePicker" class="f-line-picker">
          <div class="line-picker">
            <b-row>

              <b-col cols="2"
                     v-for="l in 24"
                     :key="l"
                     :class="['border', 'height-76', { 'active': l === line }]"
                     @click="changeLine(l)"
              >
                <div class="fix-size">
                  <div :class="`pencil-size right-${l}`">
                    <div :class="`line line-${l}`"></div>
                  </div>
                  <p class="mb-0">{{ l }}</p>
                </div>
              </b-col>

            </b-row>
          </div>
        </div>
      </div>

      <div :class="['img-center', {'height-50vh': !illust}]">
        <div ref="panHandDraw" class="fix-size" @touchstart="hiddenPicker">
          <canvas ref="canvasHandDraw"></canvas>
        </div>
      </div>
      <b-textarea class="m__top--10" rows="4" v-model="illustMemo"></b-textarea>
      <div class="d-flex justify-content-between m__top--40">
        <div>
          <img src="@/assets/images/icons/ic_delete.svg" alt="" width="30" v-if="illust" @click="deleteIllust">
        </div>
        <div>
          <b-button class="card-button" :disabled="!this.drawingMode" @click="canvasDrawModeOff">移動・拡大</b-button>
        </div>
        <div>
          <b-button class="card-button" :disabled="this.drawingMode" @click="canvasDrawModeOn">書き込み</b-button>
        </div>
        <div>
          <img src="@/assets/images/icons/ic_save.svg" alt="" width="30" @click="saveIllust">
        </div>
      </div>
    </b-modal>

    <b-modal id="photo-modal" centered hide-header hide-footer>
      <header class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="closeModal('photo-modal')">
          <img src="@/assets/images/icons/ic_exit.svg" alt="">
        </button>
      </header>

      <div class="tool-bar d-flex justify-content-center m__bottom--10" v-if="photo || capture">
        <div class="d-flex">
          <p class="mb-0">Color</p>
          <div :class="['choice', 'color-choice', { 'active': showColorPicker }]" :style="{ 'background-color': getColor }" @click="clickShowColorPicker" data-target="show-color-picker"></div>

          <p class="mb-0 ml-2">Line</p>
          <div :class="['choice', 'line-choice', { 'active': showLinePicker }]" @click="showLinePicker = !showLinePicker" data-target="show-line-picker">
            <div :style="{ 'width': line + 'px', 'height': line + 'px', 'background-color': getColor }" data-target="show-line-picker"></div>
          </div>

          <p class="mb-0 ml-2">Eraser</p>
          <div :class="['choice', 'eraser', { 'active': eraser }]" @click="eraser = !eraser"></div>
        </div>
      </div>
      <div class="position-relative">
        <div v-click-out="closeColorPicker" v-if="showColorPicker" class="f-color-picker">
          <sketch-picker v-model="color"></sketch-picker>
        </div>
        <div v-click-out="closeLinePicker" v-if="showLinePicker" class="f-line-picker">
          <div class="line-picker">
            <b-row>

              <b-col cols="2"
                     v-for="l in 24"
                     :key="l"
                     :class="['border', 'height-76', { 'active': l === line }]"
                     @click="changeLine(l)"
              >
                <div class="fix-size">
                  <div :class="`pencil-size right-${l}`">
                    <div :class="`line line-${l}`"></div>
                  </div>
                  <p class="mb-0">{{ l }}</p>
                </div>
              </b-col>

            </b-row>
          </div>
        </div>
      </div>

      <div :class="['img-center', { 'height-50vh': !capture }]" v-if="photo || capture">
        <div ref="panImgHandDraw" class="fix-size" @touchstart="hiddenPicker">
          <canvas ref="imageHandDraw"></canvas>
        </div>
      </div>

      <div class="img-center height-50vh" v-if="!photo && !capture">
        <img src="@/assets/images/icons/ic_camera.svg" alt="" width="50" @click="$refs.capture.click()">

        <input ref="capture" type="file" accept="image/*" @change="changeCapture($event)" hidden>
      </div>

      <b-textarea class="m__top--10" rows="4" v-model="photoMemo"></b-textarea>
      <div class="d-flex justify-content-between m__top--40">
        <div>
          <img src="@/assets/images/icons/ic_delete.svg" alt="" width="30" v-if="photo || capture" @click="deletePhoto">
        </div>
        <div :class="canvasImageUploaded ? 'd-block' : 'd-none'">
          <b-button class="card-button" :disabled="!this.drawingMode" @click="canvasDrawModeOff">移動・拡大</b-button>
        </div>
        <div :class="canvasImageUploaded ? 'd-block' : 'd-none'">
          <b-button class="card-button" :disabled="this.drawingMode" @click="canvasDrawModeOn">書き込み</b-button>
        </div>
        <div>
          <img src="@/assets/images/icons/ic_save.svg" alt="" width="30" @click="savePhoto">
        </div>
      </div>
    </b-modal>

    <b-modal id="concent-modal" centered hide-header hide-footer>
      <header class="modal-header">
        <button type="button" aria-label="Close" class="close" @click="closeModal('concent-modal')">
          <img src="@/assets/images/icons/ic_exit.svg" alt="">
        </button>
      </header>
      <div class="img-center height-50vh">
        <img v-if="concent" :src="concent" alt="" width="100%">
        <img v-if="concentShow" :src="getImage(concentShow.img)" alt="" width="100%">
        <img v-if="!concentShow && !concent" src="@/assets/images/icons/ic_camera.svg" alt="" width="50" @click="$refs.concent.click()">
        <input ref="concent" type="file" accept="image/*" @change="previewConcent($event)" hidden>
      </div>
      <div class="d-flex justify-content-between m__top--40">
        <div>
          <img v-if="concentShow || concent" src="@/assets/images/icons/ic_delete.svg" alt="" width="30" @click="deleteConcent">
        </div>
        <div>
          <img src="@/assets/images/icons/ic_save.svg" alt="" width="30" v-if="!concentShow" @click="saveConcent">
        </div>
      </div>
    </b-modal>

  </b-container>
</template>
<script>
import karuteService from "@/services/karute-api";
import customer from "@/mixins/customer";
import {ACTION_BACK_PAGE} from "@/store/auth/actions";
import {fabric} from 'fabric';
import {Sketch} from 'vue-color';
import panzoom from 'panzoom';
import EraserBrush from '@/utils/fabric-eraser';
import base64ToBlob from '@/utils/base64-to-blob';

export default {
  name: "Detail",
  mixins: [customer],
  components: {
    'sketch-picker': Sketch
  },
  data() {
    return {
      detail: {
        customer_history_info: {
          reserved_at: null,
          customer_history_id: "",
          named_flag: "",
          customer_history_memo: "",
          customer_id: "",
          customer_no: "",
          customer_name: "",
          customer_namekana: "",
          customer_birthday: null,
          customer_age: null,
          customer_sex: 0,
          customer_memo: null,
          photo_path: "",
          operate_staff_name: "",
          menu_array: [],
          status: "",
          times: 0,
        },
        customer_history_illust: [],
        customer_history_photo: [],
        customer_history_concent: [],
        total_point: 0,
        total_limited_point: 0
      },
      panzoom: null,
      canvas: null,
      eraser: false,
      oldFreeDrawingBrush: null,
      line: 1,
      color: {},
      showColorPicker: false,
      showLinePicker: false,
      illustMemo: "",
      capture: false,
      photoMemo: "",
      concent: null,
      illust: null,
      photo: null,
      imgUrl: '',
      concentShow: null,
      drawingMode: true,
      canvasImageUploaded: false
    }
  },
  methods: {
    canvasDrawModeOn() {
      this.canvas.discardActiveObject()
      this.canvas.isDrawingMode = true
      this.drawingMode = true
      this.panzoom.pause()
      this.eraser = false
    },
    canvasDrawModeOff() {
      this.panzoom.resume()
      this.canvas.isDrawingMode = false
      this.drawingMode = false
      let nowPan = this.panzoom
      this.canvas.on('mouse:down', function(opt) {
        var evt = opt.e
        if (evt.altKey === true) {
          this.isDragging = true
          this.selection = false
          this.lastPosX = evt.clientX
          this.lastPosY = evt.clientY
        }
      })
      this.canvas.on('mouse:move', function(opt) {
        if (this.isDragging) {
          var e = opt.e
          var vpt = this.viewportTransform
          vpt[4] += e.clientX - this.lastPosX
          vpt[5] += e.clientY - this.lastPosY
          this.requestRenderAll()
          this.lastPosX = e.clientX
          this.lastPosY = e.clientY
        }
      })
      this.canvas.on('mouse:up', function() {
        this.setViewportTransform(this.viewportTransform)
        this.isDragging = false
        this.selection = true
      })
      
      this.canvas.on("selection:created", function(){
        this.selection = true
        nowPan.pause()
      })
      this.canvas.on("selection:cleared", function(){
        this.selection = false
        nowPan.resume()
      })
    },
    customerReserved() {
      this.$router.push({
        name: 'customer-reserved',
        params: {
          customer_no: this.customerNo,
          customer_id: this.customerId,
          customer_history_id: this.customerHistoryId,
          target_date_from: this.targetDateFrom,
          target_date_to: this.targetDateTo,
        }
      })
    },
    getReserveInfo() {
      const data = {
        customer_id: this.customerId,
        customer_history_id: this.customerHistoryId,
      }
      karuteService.getReserveInfo(data).then((res) => {
        this.detail = res.data.result
      })
    },
    closeModal(modal) {
      this.$root.$emit("bv::hide::modal", modal)
      this.drawingMode = true
    },
    showIllust(illust) {
      this.drawingMode = true
      this.illust = illust
      this.illustMemo = this.illust.comment
      this.$root.$emit("bv::show::modal", 'illust-modal')
    },
    illustModalShown() {
      this.drawingMode = true
      this.canvas = new fabric.Canvas(this.$refs.canvasHandDraw, {
        preserveObjectStacking: true,
        controlsAboveOverlay: true,
        allowTouchScrolling: true
      })
      this.canvas.isDrawingMode = true

      this.panzoom = panzoom(this.$refs.panHandDraw)
      this.panzoom.pause()
      
      // Reset draw
      this.eraser = false
      this.line = 1
      this.color = {}

      this.canvas.setHeight(document.getElementsByClassName('fix-size')[0].offsetHeight)
      this.canvas.setWidth(document.getElementsByClassName('fix-size')[0].offsetWidth)

      this.canvas.freeDrawingBrush.color = this.getColor
      this.canvas.freeDrawingBrush.width = this.line

      this.oldFreeDrawingBrush = this.canvas.freeDrawingBrush

      if (this.illust) {
        fabric.Image.fromURL("data:image/png;base64," + this.illust.img, imgObj => {
          this.canvas.add(imgObj);

          imgObj.scaleToWidth(this.canvas.width)

          let canvasHeight = (this.canvas.width / imgObj.width) * imgObj.height
          this.canvas.setHeight(canvasHeight)

          this.canvas.renderAll()
        });

        // todo clear current draw
        this.canvas.clear()
      }
    },
    clickShowColorPicker() {
      this.eraser = false
      this.showColorPicker = !this.showColorPicker
    },
    changeLine(line) {
      this.line = line
    },
    saveIllust() {
      let data = {
        customer_id: this.customerId,
        customer_history_id: this.customerHistoryId,
        img: this.canvas.toDataURL('png').replace('data:image/png;base64,', ''),
        comment: this.illustMemo,
      }

      if (this.illust) {
        data.customer_history_illust_id = this.illust.customer_history_illust_id
        karuteService.putCustomerHistoryIllust(data).then(() => {
          this.getReserveInfo()
          this.closeModal('illust-modal')
          this.$toast.success(this.$t('detail.messages.upload_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.image_upload_fail'))
          this.closeModal('illust-modal')
        })
      } else {
        karuteService.postCustomerHistoryIllust(data).then(() => {
          this.getReserveInfo()
          this.closeModal('illust-modal')
          this.$toast.success(this.$t('detail.messages.saved'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.image_upload_fail'))
          this.closeModal('illust-modal')
        })
      }
    },
    deleteIllust() {
      karuteService.deleteCustomerHistoryIllust(this.illust.customer_history_illust_id).then(() => {
        this.getReserveInfo()
        this.closeModal('illust-modal')
        this.$toast.success(this.$t('detail.messages.delete_success'))
      }).catch(() => {
        this.$toast.error(this.$t('detail.messages.fail'))
        this.closeModal('illust-modal')
      })
    },
    savePhoto() {
      this.panzoom.zoomAbs(0, 0, 1);
      this.panzoom.moveTo(0, 0)
      this.panzoom.pause()
      let base64 =  this.canvas.toDataURL({
        format: 'png',
      })
      base64 = base64.replace(/^data:image\/(png|jpg);base64,/, "")
      let image = base64ToBlob(base64, 'image/png')

      let data = {
        customer_id: this.customerId,
        customer_history_id: this.customerHistoryId,
        history_photo: image,
        comment: this.photoMemo,
      }

      if (this.photo) {
        data.customer_history_photo_id = this.photo.customer_history_photo_id

        karuteService.putCustomerHistoryPhoto(data).then(() => {
          this.getReserveInfo()
          this.closeModal('photo-modal')
          this.$toast.success(this.$t('detail.messages.upload_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.image_upload_fail'))
          this.closeModal('photo-modal')
        })
      } else if (this.canvas) {
        karuteService.postCustomerHistoryPhoto(data).then(() => {
          this.getReserveInfo()
          this.closeModal('photo-modal')
          this.$toast.success(this.$t('detail.messages.upload_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.image_upload_fail'))
          this.closeModal('photo-modal')
        })
      } else {
        this.$toast.info(this.$t('detail.messages.choose_image'))
      }

    },
    deletePhoto() {
      if (this.photo) {
        karuteService.deleteCustomerHistoryPhoto(this.photo.customer_history_photo_id).then(() => {
          this.getReserveInfo()
          this.closeModal('photo-modal')
          this.$toast.success(this.$t('detail.messages.delete_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.fail'))
          this.closeModal('photo-modal')
        })
      } else {
        this.capture = false
        this.canvas = null
      }
    },
    showPhoto(event, photo) {
      this.drawingMode = true
      let data = {
        customer_id: this.customerId,
        customer_history_id: this.customerHistoryId,
        customer_history_photo_id: photo.customer_history_photo_id,
      }
      karuteService.getCustomerHistoryPhoto(data).then((res) => {
        this.photo = photo
        this.photoMemo = photo.comment
        this.$root.$emit("bv::show::modal", 'photo-modal')
        this.canvasImageUploaded = true
        this.imgUrl = res.data.result.img_base64 ? 'data:image/png;base64,' + res.data.result.img_base64 : ''
        this.previewCapture(this.imgUrl)
      }).catch(() => {
        this.$toast.error(this.$t('detail.messages.fail'))
      })
    },
    changeCapture(event) {
      if (event.target.files.length > 0) {
        this.previewCapture(URL.createObjectURL(event.target.files[0]))
        this.canvasImageUploaded = true
        this.drawingMode = true
      }
    },
    previewCapture(imgUrl) {
      this.capture = !!imgUrl
      setTimeout(() => {
        this.canvas = new fabric.Canvas(this.$refs.imageHandDraw, {
          preserveObjectStacking: true,
          controlsAboveOverlay: true,
          allowTouchScrolling: true
        })
        this.canvas.isDrawingMode = true
        this.panzoom = panzoom(this.$refs.panImgHandDraw)
        this.panzoom.pause()
        // Reset draw
        this.eraser = false
        this.line = 1
        this.color = {}

        this.canvas.setWidth(document.getElementsByClassName('fix-size')[0].offsetWidth)

        this.canvas.freeDrawingBrush.color = this.getColor
        this.canvas.freeDrawingBrush.width = this.line

        this.oldFreeDrawingBrush = this.canvas.freeDrawingBrush

        if (imgUrl) {
          fabric.Image.fromURL(imgUrl, imgObj => {
                this.canvas.add(imgObj);
                imgObj.scaleToWidth(this.canvas.width)

                let canvasHeight = (this.canvas.width / imgObj.width) * imgObj.height
                this.canvas.setHeight(canvasHeight)

                this.canvas.renderAll()
              }
          )
        } else {
          this.canvas.setHeight(document.getElementsByClassName('fix-size')[0].offsetHeight)
        }

        // todo clear current draw
        this.canvas.clear()
      })
    },
    showConcent(concentShow) {
      this.drawingMode = true
      this.concentShow = concentShow
      this.$root.$emit("bv::show::modal", 'concent-modal')
    },
    previewConcent(e) {
      this.concent = e.target.files.length > 0 ? URL.createObjectURL(e.target.files[0]) : null
    },
    saveConcent() {
      console.log(this.$refs.concent);
      if (this.concent && this.$refs.concent.files.length > 0) {
        let data = {
          customer_id: this.customerId,
          customer_history_id: this.customerHistoryId,
          history_concent: this.$refs.concent.files[0],
          comment: this.photoMemo,
        }

        karuteService.postCustomerHistoryConcent(data).then(() => {
          this.getReserveInfo()
          this.closeModal('concent-modal')
          this.$toast.success(this.$t('detail.messages.upload_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.image_upload_fail'))
          this.closeModal('concent-modal')
        })
      } else {
        this.$toast.info(this.$t('detail.messages.choose_image'))
      }
    },
    deleteConcent() {
      if (this.concentShow) {
        karuteService.deleteCustomerHistoryConcent(this.concentShow.customer_history_concent_id).then(() => {
          this.getReserveInfo()
          this.closeModal('concent-modal')
          this.$toast.success(this.$t('detail.messages.delete_success'))
        }).catch(() => {
          this.$toast.error(this.$t('detail.messages.fail'))
          this.closeModal('concent-modal')
        })
      } else {
        this.concent = null
        this.$refs.concent.value = null
      }
    },
    closeColorPicker(el) {
      if (el.target.getAttribute('data-target') !== 'show-color-picker') {
        this.showColorPicker = false
      }
    },
    closeLinePicker(el) {
      if (el.target.getAttribute('data-target') !== 'show-line-picker') {
        this.showLinePicker = false
      }
    },
    hiddenPicker() {
      this.showColorPicker = false
      this.showLinePicker = false
    },
    changeCustomerMemo() {
      let data = {
        customer_id: this.customerId,
        customer_memo: this.detail.customer_history_info.customer_memo,
      }
      karuteService.putCustomerMemo(data).then(() => {
        this.$toast.success(this.$t('detail.messages.saved'))
      }).catch(() => {
        this.$toast.error(this.$t('detail.messages.fail'))
      })
    },
    changeCustomerHistoryMemo() {
      let data = {
        customer_id: this.customerId,
        customer_history_id: this.customerHistoryId,
        customer_history_memo: this.detail.customer_history_info.customer_history_memo,
      }
      karuteService.putCustomerHistoryMemo(data).then(() => {
        this.$toast.success(this.$t('detail.messages.saved'))
      }).catch(() => {
        this.$toast.error(this.$t('detail.messages.fail'))
      })
    },
  },
  watch: {
    locationSelected() {
      this.$router.push({name: 'home'}).then(() => {
        // Customer detail is no longer accessible since store is changed
      });
    },
    color() {
      this.canvas.freeDrawingBrush.color = this.getColor
    },
    line() {
      this.canvas.freeDrawingBrush.width = this.line
    },
    eraser() {
      if (this.eraser) {
        const eraserBrush = new EraserBrush(this.canvas)
        eraserBrush.width = this.line > 10 ? this.line : 10
        eraserBrush.color = '#cccccc'
        this.canvas.freeDrawingBrush = eraserBrush
      } else {
        this.canvas.freeDrawingBrush = this.oldFreeDrawingBrush
        this.canvas.freeDrawingBrush.width = this.line
      }
    },
  },
  computed: {
    getColor() {
      // eslint-disable-next-line no-undef
      return _.isEmpty(this.color) ? 'black': 'rgba(' + this.color.rgba.r +', ' + this.color.rgba.g +', ' + this.color.rgba.b +', ' + this.color.rgba.a +')'
    },
    gender() {
      if (this.detail.customer_history_info.customer_sex === 1) {
        return '男性'
      }
      if (this.detail.customer_history_info.customer_sex === 2) {
        return '女性'
      }
      return '-'
    }
  },
  created() {
    this.$store.dispatch(ACTION_BACK_PAGE, 'search')
  },
  mounted() {
    this.getReserveInfo()

    // eslint-disable-next-line no-unused-vars
    this.$root.$on('bv::modal::hidden', (bvEvent, modalId) => {
      this.canvas = null
      this.oldFreeDrawingBrush = null
      this.showColorPicker = false
      this.showLinePicker = false
      this.illustMemo = ""
      this.capture = false
      this.photoMemo = ""
      this.concent = null
      this.illust = null
      this.photo = null
      this.imgUrl = ''
      this.concentShow = null
    })
  }

}
</script>

<style lang="scss" scoped>
.container {
  &:last-child {
    padding-bottom: 20px;
  }

  .row {
    margin-left: 0;
    margin-right: 0;

    ::v-deep .col {
      font-size: 14px;

      .card-timer {
        text-align: center;
        height: fit-content;
        border-radius: 10px;
        font-size: 10px;
        line-height: 2;

        .card-header {
          padding: 0;
          background-color: $grey;
          color: $white;
          border-radius: 9px 9px 0 0;
        }

        .card-timer-content {
          text-align: center;
        }

        .card-body {
          padding: 0;
        }
      }
    }
  }
}

.card-container {
    &.is-group {
      ::v-deep {
        .card {
          .card-header {
            padding: 0.3rem 1rem !important;
          }
        }
      }
    }
  ::v-deep {
    .card {
      height: fit-content;
      font-size: 1rem;
      border: none;

      &:first-child {
        border-radius: 20px 20px 0 0;

        .card-header {
          border-radius: 20px 20px 0 0;
        }
      }

      &:not(:first-child) {
        .card-header {
          border-radius: unset;
        }
      }

      &:last-child {
        border-radius: 20px;

        .card-footer {
          border-radius: 0 0 20px 20px;
        }
      }

      .card-header {
        background-color: $grey;
        color: $white;
        padding: 0.5rem 1rem;

        .border-bottom-light {
          border-bottom: 1px solid $light;
        }
      }
      .card-timer-content {
        text-align: center;
      }

      .card-body {
        padding: 0;

        .card-text {
          font-size: 12px;
          margin: 0;

          &.border-bottom-grey {
            border-bottom: 1px solid #cecaca;
          }

          .form-control {
            font-size: 12px;
            border-radius: 12px;
          }
        }
      }

      .card-footer {
        background-color: $white;
        border-radius: 0 0 20px 20px;
      }

      .card-button {
        font-size: 12px;
        padding: 4px 20px;
        border-radius: 6px;
        background-color: $grey;
      }
    }
  }

  &.is-pink {
    ::v-deep {
      .card {
        .card-header {
          background-color: $pink;
          font-size: 14px;
        }
      }
    }
  }
}

.f-radius {
  border-radius: 20px;
  font-size: 1.1rem;
  line-height: 30px;

  &.b-white {
    background: #ffffff;
  }
}

.search-result-item {
  padding: 0.5rem 0;
  display: flex;
  margin: 0;

  .thumbnail {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 80px;
    height: 80px;
    border-radius: 20px;
    overflow: hidden;
    background-color: #E6E6E6;

    .img {
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  .flex-auto {
    flex: auto;
  }
}

/*Custom bootstrap modal*/
::v-deep .modal-content {
  border-radius: 26px !important;

  .modal-header {
    border-bottom: none;
    padding: 0;
    margin-bottom: 1.5rem;

    .close {
      line-height: 18px;

      img {
        width: 26px !important;
      }
    }
  }

  .tool-bar {
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: $light-grey;

    .choice {
      width: 26px;
      height: 26px;
      border: 1px solid #fff;
      margin-left: 3px;

      &.active {
        border: 1px solid $pink;
      }

      &.eraser {
        background-color: $white;
      }
    }

    .line-choice {
      background: $white;
      display: flex;
      justify-content: center;
      align-items: center;

      div {
        border-radius: 100%;
      }
    }
  }

  .f-color-picker {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99;
  }

  .f-line-picker {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 100;

    .line-picker {
      position: relative;
      width: 200px;
      padding: 0 15px;
      box-sizing: initial;
      background: #fff;
      border-radius: 4px;
      overflow: hidden;
      border: 1px solid;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, .15), 0 8px 16px rgba(0, 0, 0, .15);

      .active {
        border: 1px solid $pink !important;
      }

      .fix-size {
        position: relative;

        .pencil-size {
          position: absolute;
          top: 10px;
          display: flex;
          align-items: center;
          justify-content: center;
          left: 50%;
          transform: translateX(-50%);

          .line {
            background-color: $black;
          }

          @for $i from 1 through 24 {
            .line-#{$i} {
              width: #{$i}px;
              height: #{$i}px;
              border-radius: 100%;
              overflow: hidden;
            }
          }
        }

        p {
          position: absolute;
          top: 40px;
        }
      }
    }

    .height-76 {
      height: 76px;
    }
  }


  .img-center {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    border: 1px solid;
    overflow: hidden;

    &.height-50vh {
      height: 50vh;
    }

    .fix-size {
      width: 100%;
      height: 100%;
      overflow: hidden;
      z-index: 2;
      border-radius: 20px;
      background-color: #CCCCCC;
    }

    canvas {
      background: transparent !important;
    }

    img {
    }
  }

  .form-control {
    font-size: 12px;
    border-radius: 12px;
  }
}

.font-size-1 {
  font-size: 1rem;
}

.font-weight-600 {
  font-weight: 600;
}

.fs-12 {
  font-size: 12px;
}

.fs-14 {
  font-size: 14px;
}
</style>
