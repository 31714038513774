export default {
    computed: {
        customerNo() {
            return this.$route.params.customer_no ?? ''
        },
        customerId() {
            return this.$route.params.customer_id ?? ''
        },
        customerHistoryId() {
            return this.$route.params.customer_history_id ?? ''
        },
        targetDateFrom() {
            return this.$route.params.target_date_from ?? ''
        },
        targetDateTo() {
            return this.$route.params.target_date_to ?? ''
        }
    },
}
