import webApi from "./api";
import qs from "querystring";

export class KaruteService {
    static key = process.env.VUE_APP_API_KEY

    getHomeInfo(date) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            staff_id: loginInfo.staff_id,
            target_date: date,
        };
        return webApi.post('get_home_info', qs.stringify(param));
    }

    getReserveList(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            staff_id: loginInfo.staff_id,
            target_date_from: data.target_date_from,
            target_date_to: data.target_date_to,
            range: data.range ?? null,
            status: data.status ?? null,
            customer_name: data.customer_name ?? null,
            customer_no: data.customer_no ?? null,
            customer_id: data.customer_id ?? null,
        };
        return webApi.post('get_reserve_list', qs.stringify(param));
    }

    getReserveInfo(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            staff_id: loginInfo.staff_id,
            customer_history_id: data.customer_history_id,
            customer_id: data.customer_id,
        };
        return webApi.post('get_reserve_info', qs.stringify(param));
    }

    postCustomerHistoryIllust(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_id: data.customer_history_id,
            customer_id: data.customer_id,
            img: data.img,
            comment: data.comment,
        };
        return webApi.post('post_customer_history_illust', qs.stringify(param));
    }

    putCustomerHistoryIllust(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_id: data.customer_history_id,
            customer_id: data.customer_id,
            img: data.img,
            comment: data.comment,
            customer_history_illust_id: data.customer_history_illust_id,
        };
        return webApi.post('put_customer_history_illust', qs.stringify(param));
    }

    deleteCustomerHistoryIllust(customer_history_illust_id) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_illust_id: customer_history_illust_id,
        };
        return webApi.post('delete_customer_history_illust', qs.stringify(param));
    }

    getCustomerHistoryPhoto(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_id: data.customer_history_id,
            customer_id: data.customer_id,
            customer_history_photo_id: data.customer_history_photo_id,
        };
        return webApi.post('get_customer_history_photo', qs.stringify(param));
    }

    postCustomerHistoryPhoto(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let params = new FormData()
        params.append('key', KaruteService.key)
        params.append('shop_id', loginInfo.shop_id)
        params.append('customer_history_id', data.customer_history_id)
        params.append('customer_id', data.customer_id)
        params.append('history_photo', data.history_photo)
        params.append('comment', data.comment)

        return webApi.post('post_customer_history_photo', params);
    }

    putCustomerHistoryPhoto(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let params = new FormData()
        params.append('key', KaruteService.key)
        params.append('shop_id', loginInfo.shop_id)
        params.append('customer_history_id', data.customer_history_id)
        params.append('customer_id', data.customer_id)
        params.append('history_photo', data.history_photo)
        params.append('comment', data.comment)
        params.append('customer_history_photo_id', data.customer_history_photo_id)

        return webApi.post('put_customer_history_photo', params);
    }

    deleteCustomerHistoryPhoto(customer_history_photo_id) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_photo_id: customer_history_photo_id,
        };
        return webApi.post('delete_customer_history_photo', qs.stringify(param));
    }

    postCustomerHistoryConcent(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let params = new FormData()
        params.append('key', KaruteService.key)
        params.append('shop_id', loginInfo.shop_id)
        params.append('customer_history_id', data.customer_history_id)
        params.append('customer_id', data.customer_id)
        params.append('history_concent', data.history_concent)

        return webApi.post('post_customer_history_concent', params);
    }

    deleteCustomerHistoryConcent(customer_history_concent_id) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_concent_id: customer_history_concent_id,
        };
        return webApi.post('delete_customer_history_concent', qs.stringify(param));
    }

    putCustomerMemo(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_id: data.customer_id,
            customer_memo: data.customer_memo,
        };
        return webApi.post('put_customer_memo', qs.stringify(param));
    }

    putCustomerHistoryMemo(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_history_id: data.customer_history_id,
            customer_id: data.customer_id,
            customer_history_memo: data.customer_history_memo,
        };
        return webApi.post('put_customer_history_memo', qs.stringify(param));
    }

    getCustomerReserveList(data) {
        let loginInfo = JSON.parse(localStorage.getItem('login_info'))
        if (!loginInfo) return
        let param = {
            key: KaruteService.key,
            shop_id: loginInfo.shop_id,
            customer_id: data.customer_id,
        };
        return webApi.post('get_customer_reserve_list', qs.stringify(param));
    }
}

const karuteService = new KaruteService();

export default karuteService;
